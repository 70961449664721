import React, { Fragment, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components'

import parseHtml from '../parseHtml/parseHtml';
import Request from '../Request/Request'
import Data from '../Data/Data-dynamic';

const Ahover = styled.div`
  position:relative;
  a { text-decoration:none; color:black; display:inline-block; padding-bottom:0; border-bottom:2px solid rgba(0,0,0,0); }
  a:hover { border-bottom:2px solid rgb(32, 190, 225); }
`;

const Divider = props => (
  <div>
    <div style={{ padding:'5px 0', margin:'3px', width:'2px', height:'16px', background:'#ccc', transform:'translateY(4px)'  }} />
  </div>
)

const EditButton = props => {
  return (
    <div
      style={ props.style }
      className="toolbar-icon-hover"
      key={ props.cmd }
      onMouseDown={ evt => {
        evt.preventDefault(); // Avoids loosing focus from the editable area
        document.execCommand( props.cmd, false, props.arg ); // Send the command to the browser
      }}
    >
      { props.name &&
        <div 
          title={ props.title } 
          style={{ padding:'5px', margin:'3px', width:'24px', height:'24px', textAlign:'center', fontWeight:'700', fontSize:'18px', transform:'translateY(0px)' }} 
        >
          { props.name }
        </div>
      }
      { props.icon && 
        <div 
          title={ props.title } 
          style={{ padding:'5px', margin:'3px', width:'24px', height:'24px' }} className="material-icons"
        >
          { props.icon }
        </div>
      }
    </div>
  )
}

const EditableColumn = props => {

  const text = useRef('')
  const [ focused, setFocused ] = useState( false )

  const handleChange = evt => {
    text.current = evt.target.value
  }

  const handleFocus = () => {
    setFocused( true )
  }
  
  const handleBlur = () => {
    //console.log( 'handleBlur text.current', text.current )
    setFocused( false )
    if ( text.current && text.current !== '' ) {
      let newStructure = props.structure
      newStructure[ props.columnName ] = sanitizeHtml( text.current ) 
      props.setStructure( newStructure )

      Request.put( props.user, 'page-structure', { 
          id:props.page.id, 
          structure:JSON.stringify( newStructure ) 
        }, () => {
          Data().fetch( ( data ) => {
            text.current = ''
            //props.setRefresh( props.refresh + 1 )
            //console.log( 'did props.refresh', props.refresh )
          } )
      } )

    } else {
      //console.log( 'nothing to save' )
    }
  }

  const CustomTag = `${ props.tagName }`;

  //console.log( 'render props.refresh', props.refresh )

  return(

    <Fragment>

      { !props.user && props.showHovers &&  
        <Ahover 
          //key={ props.page.id + '-' + props.refresh } 
          style={ props.style } 
          className={ props.className } 
        >
          { parseHtml( props.structure[ props.columnName ] || '' ) }
        </Ahover>
      }

      { !props.user && !props.showHovers &&  
        <CustomTag 
          //key={ props.page.id + '-' + props.refresh } 
          style={ props.style } 
          className={ props.className } 
        >
          { parseHtml( props.structure[ props.columnName ] || '' ) }
        </CustomTag>
      }

      { props.user &&
        <ContentEditable
          //key={ props.page.id + '-' + props.refresh }
          tagName={ props.tagName }
          className={ props.className }
          html={ props.structure[ props.columnName ] }
          disabled={ !props.user }
          onFocus={ handleFocus }
          onBlur={ handleBlur }
          onChange={ handleChange }
          style={ props.style }
        />
      }

      { props.user && props.showCommands && focused && 
        <div style={{ 
          position:'fixed', left:'50%', bottom:'20px', transform:'translateX(-50%)', 
          display:'flex', zIndex:'100', 
          userSelect:'none', cursor:'pointer', 
          background:'rgba(255,255,255,1)', 
          padding:'5px', 
          color:'black',
          boxShadow:'0 3px 6px rgba(0,0,0,0.4)', 
          //borderRadius:'22px', 
          boxSizing:'border-box' 
        }} >

          <EditButton cmd="formatBlock" arg="p" name="P" title="Paragraph" />
          <EditButton cmd="formatBlock" arg="h1" name="1" title="Main Header H1" />
          <EditButton cmd="formatBlock" arg="h2" name="2" title="Secondary Header H2" />
          <EditButton cmd="formatBlock" arg="h3" name="3" title="Tertiary Header H3" />
          <Divider/>
          <EditButton cmd="justifyLeft" icon="format_align_left" title="Align Left" />
          <EditButton cmd="justifyCenter" icon="format_align_center" title="Align Center" />
          <EditButton cmd="justifyRight" icon="format_align_right" title="Align Right" />
          <Divider/>
          <EditButton cmd="insertUnorderedList" icon="format_list_bulleted" title="Bulleted List" />
          <EditButton cmd="insertOrderedList" icon="format_list_numbered" title="Ordered List" />
          <Divider/>
          <EditButton cmd="bold" icon="format_bold" title="Bold" />
          <EditButton cmd="italic" icon="format_italic" title="Italic" />
          <EditButton cmd="underline" icon="format_underline" title="Underline" />
          <Divider/>
          <EditButton cmd="removeFormat" icon="clear_all" title="Clear Formatting" />
          {/*
          <EditButton cmd="formatBlock" arg="h3" name="H3" />
          <Divider/>
          <EditButton cmd="insertHorizontalRule" icon="horizontal_rule" />
          <Divider/>
          <EditButton cmd="insertHorizontalRule" icon="code" />
          <EditButton cmd="insertHorizontalRule" icon="clear" />
          */}

        </div>
      }

    </Fragment>

  )
}

export default EditableColumn