import React, { Fragment, useState, useEffect, setState } from 'react';
import { BrowserRouter as Router, Switch, useHistory, Route, Redirect, Link, useLocation } from 'react-router-dom'; // must import Router, else switch doesn't work
import ReactGA from 'react-ga';

import Store from '../Store/Store';

import Login from '../Login/Login';

import LongScroll from '../LongScroll/LongScroll';
//import Equities from '../Equities/Equities';
//import Security from '../Equities/Security';
//import FrontPage from '../Intro/FrontPage';
//import Quotations from '../Intro/Quotations';
//import News from '../Intro/News';
import BlogPage from '../Blogs/BlogPage';
import NewsPage from '../News/NewsPage';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Cookies from '../Cookies/Cookies';
//import Background from '../Fiber/Background';
import '../css/index.css';
import Background from '../Fiber/DiamondsBackground';

/*
const Logout = props => {

  let history = useHistory();
  const [ tmp, setTmp ] = useState( 0 );

  return (
    <div 
      style={{ position:'fixed', cursor:'pointer', userSeöect:'none', verticalAlign:'top', bottom:0, right:0, padding:'0px 10px 0 15px', margin:'20px', background:'rgba(255,255,255,0.8)', borderRadius:'20px', color:'#000', fontWeight:'700', fontSize:'12px', boxShadow:'0 2px 4px rgba(0,0,0,0.3)' }}
      onClick={ () => { props.setUser( null ); } }
    >
      <div style={{ display:'inline-block', padding:'0 5px 0 0', transform:'translateY(-3px)' }} >
      { props.user.last_name + ' ' + props.user.first_name }
      </div>
      <div style={{ display:'inline-block', padding:'0', transform:'translateY(4px)', fontSize:'12px' }} >
        <span className="material-icons">{ "logout" }</span>
      </div>

    </div>
  )
}
*/

const LoginBadge = props => {

  let history = useHistory();
  const [ tmp, setTmp ] = useState( 0 );

  //let route = props && props.match && props.match.params ? props.match.params.route : null

  //const { pathname } = props.location;
  const location = useLocation()
  //console.log( 'LoginBadge', route, location )

  if ( location.pathname === '/login/' ) return null

  return (
    <Link to="/login/">
      <div 
        style={{ position:'fixed', cursor:'pointer', userSeöect:'none', verticalAlign:'top', bottom:0, right:0, padding:'5px 10px 5px 10px', margin:'20px', background:'rgba(255,255,255,0.8)', borderRadius:'20px', color:'#000', fontWeight:'700', fontSize:'12px', boxShadow:'0 2px 4px rgba(0,0,0,0.3)' }}
      >
        <div style={{ display:'inline-block', padding:'0', transform:'translate(-2px,6px)', fontSize:'12px' }} >
          <span className="material-icons">{ "login" }</span>
        </div>

      </div>
    </Link>
  )
}



const App = ( props ) => {

  const [ tmp, setTmp ] = useState( 0 );
  const [ offset, setOffset ] = useState(0);
  const [ top, setTop ] = useState( true );
  const [ user, setUser ] = useState( null );
  const [ lang, setLang ] = useState( Store.get( 'lang' ) )

  useEffect(() => {
    window.onscroll = () => {
      const tmpTop = window.pageYOffset < 25 
      if ( Store.get( "pageTop" ) !== tmpTop ) {
        Store.set( "pageTop", tmpTop )
        setTop( tmpTop )
      }
    }
  }, []);

  //ReactGA.initialize('GTM-K6V5BGW');

  //ReactGA.pageview(window.location.pathname + window.location.search);

  //let user = Store.get( 'user' )
  //console.log( 'app render user', user )
  //console.log( 'app again' )

  /*
  if ( !user ) {
    return (
      <Login setUser={ setUser } />
    )  
  } else {
  */

  //console.log( 'pageTop', pageTop )

  return (
    <Fragment>
      <Router basename={ process.env.PUBLIC_URL }>
        <Fragment>
          {/*
          <Background/>
          */}
          <Switch>

              <Redirect exact from="/start/" to="/start/first/" />
              <Route exact path="/login/" render={ routeProps => <Login { ...routeProps } setUser={ setUser } />} />
              <Route exact path="/:section/" render={ routeProps => <LongScroll { ...routeProps } user={ user } />} />
              <Route exact path="/:section/:slug/" render={ routeProps => <LongScroll { ...routeProps } user={ user } />} />
              <Route exact path="/:section/:slug/:sub/" render={ routeProps => <LongScroll { ...routeProps } user={ user } />} />
              <Redirect from="*" to="/start/first" />

          </Switch>

          <Fragment>
            <Footer lang={ lang } user={ user } />
            <Route path="/:route/" render={ routeProps => <Header { ...routeProps } lang={ lang } setLang={ setLang } top={ top } user={ user } setUser={ setUser } />} />
            <Route exact path="/" render={ routeProps => <Header { ...routeProps } lang={ lang } setLang={ setLang } top={ top } user={ user } setUser={ setUser } />} />

            {/* user &&
              <Logout setUser={ setUser } user={ user } />
            */}

            {/* <LoginBadge/>  */}

            <Cookies/>
          </Fragment>

        </Fragment>
      </Router>
    </Fragment>
  ) 
  }
//}

export default App 
