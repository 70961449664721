import React from 'react';
import EditableColumn from '../EditableColumn/EditableColumn'

const Generic = props => {

  //let lang = Store.get( 'lang' )
  //let title = props.page.title || props.title

  return (
  <div className="products-wrapper" style={{ 
    backgroundColor:props.structure.backgroundColor || '#fff', 
    color:props.structure.color || '#000', 
    padding:'0', 
    boxSizing:'border-box', 
  }} >
    <div className="products" style={{ maxWidth:'900px' }}  >

      {/* title && 
      <h1 style={{ padding:'30px 30px 10px 30px', margin:'0', boxSizing:'border-box' }}>{ parseHtml( title  || '' ) }</h1>
        user={ props.user }
        page={ props.page }
        structure={ props.structure }
        setStructure={ props.setStructure }
      */}

      <EditableColumn 
        tagName="h1"
        columnName="titleFi"
        style={{ padding:'30px 30px 10px 30px', margin:'0', boxSizing:'border-box' }}
        showCommands={ false }
        showHovers={ false }
        {...props}
      />

      <EditableColumn 
        tagName="div"
        columnName="column"
        style={{ padding:'10px 30px', margin:'0', fontSize:'15px', boxSizing:'border-box' }}
        showCommands={ true }
        showHovers={ true }
        {...props}
      />

    </div>
  </div>
  )
}
export default Generic
